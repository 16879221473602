<template>
  <v-container>
    <v-btn text @click="$router.go(-1)" ><v-icon
        left
        dark
      >
        mdi-backburger
    </v-icon> Zurück zu Referenzen</v-btn>
    <ImageGallery v-model="galleryData" />
  </v-container>
</template>
<script>
import ImageGallery from '@/components/ImageGallery.vue';

export default {
  name: 'eintracht-treppenhaus',
  components: {
    ImageGallery,
  },
  data: () => ({
    galleryData: {
      imagePath:
        'https://medo-sanierung.de/public_files/img/references/eintracht-treppenhaus',
      imageCount: 13,
      imageFileFormat: '.jpg',
      name: 'Renovierung des Treppenhauses in der Eintrachter Turnabteilung',
      description:
        'Nach Abbruch und Demontage folgten die Verputzarbeiten. Einige Kabeln, die vorher sichtbar waren, verschwanden unter dem neuen Putz.Anschließend wurden alle Decken und Wände gestrichen und farblich abgesetzt, sowie Lackierarbeiten am Treppengeländer ausgeführt. Zum Schluß kamen die Boden- bzw. Treppenbelagsarbeiten, inkl. Wangen und Blenden.',
    },
    //
  }),
};
</script>
