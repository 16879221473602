var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v(_vm._s(_vm.gallyeryName))]),_c('span',[_vm._v(_vm._s(_vm.galleryDescription))]),_c('v-row',{attrs:{"justify":"center"}},_vm._l((_vm.value.imageCount),function(n){return _c('v-col',{key:n,attrs:{"cols":"12","lg":_vm.lg,"md":_vm.md,"xs":"12"}},[_c('v-lazy',{attrs:{"options":{
          threshold: 0.15,
          // viel Prozent sichtbar sein müssen, bevor es gerendert wird
        },"min-height":"300","transition":"fade-transition"}},[_c('v-card',{staticClass:"mx-auto",on:{"click":function($event){_vm.imageFullscreenView = {
              active: true,
              images: _vm.getImageObjects(),
              index: n - 1, //n-1 weil  bilder bei der ID 1 anfangen
            }}}},[_c('v-img',{attrs:{"src":_vm.value.imagePath + '/' + n + _vm.value.imageFileFormat,"height":"300","lazy-src":_vm.value.imagePath + '_lazy/' + n + _vm.value.imageFileFormat},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)})],1)],1)],1)}),1),(_vm.imageFullscreenView.active)?_c('ImageFullscreenView',{on:{"fullscreenClose":function($event){_vm.imageFullscreenView.active = false}},model:{value:(_vm.imageFullscreenView),callback:function ($$v) {_vm.imageFullscreenView=$$v},expression:"imageFullscreenView"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }