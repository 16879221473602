<template>
  <div>
    <h2>{{ gallyeryName }}</h2>
    <span>{{ galleryDescription }}</span>
    <v-row justify="center"
      ><v-col
        v-for="n in value.imageCount"
        :key="n"
        cols="12"
        :lg="lg"
        :md="md"
        xs="12"
        ><v-lazy
          :options="{
            threshold: 0.15,
            // viel Prozent sichtbar sein müssen, bevor es gerendert wird
          }"
          min-height="300"
          transition="fade-transition"
          ><v-card
            class="mx-auto"
            @click="
              imageFullscreenView = {
                active: true,
                images: getImageObjects(),
                index: n - 1, //n-1 weil  bilder bei der ID 1 anfangen
              }
            "
          >
            <v-img
              :src="value.imagePath + '/' + n + value.imageFileFormat"
              height="300"
              :lazy-src="value.imagePath + '_lazy/' + n + value.imageFileFormat"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-card>
        </v-lazy>
      </v-col>
    </v-row>
    <ImageFullscreenView
      v-if="imageFullscreenView.active"
      v-model="imageFullscreenView"
      @fullscreenClose="imageFullscreenView.active = false"
    />
  </div>
</template>

<script>
import ImageFullscreenView from '@/components/ImageFullscreenView.vue';

export default {
  name: 'ImageGallery',
  props: ['value'],
  components: {
    ImageFullscreenView,
  },
  methods: {
    getImageObjects() {
      const imagesObject = [];
      if (this.imageCount > 0) {
        for (let n = 1; n <= this.imageCount; n += 1) {
          /* eslint-disable prefer-object-spread */
          imagesObject.push({
            imageSrc: this.imagePath + '/' + n + this.imageFileFormat,
          });
        }
      }
      return imagesObject;
    },
  },
  data: () => ({
    imageFullscreenView: {
      active: false,
      images: Object,
    },
    md: '4',
    lg: '3',
    gallyeryName: null,
    galleryDescription: null,
    imageCount: Number,
    imagePath: String,
    imageFileFormat: String,
    //
  }),
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        if (newVal.split === true) {
          this.md = '6';
          this.lg = '6';
        }
        this.gallyeryName = newVal.name;
        this.galleryDescription = newVal.description;
        this.imageCount = parseInt(newVal.imageCount, 0);
        this.imagePath = newVal.imagePath;
        this.imageFileFormat = newVal.imageFileFormat;
      },
    },
  },
};

/* Json Parameter component <ImageGallery v-model="<value>" />

    jsonName: {
      imagePath: 'img/references/kraftraum/',
      imageCount: 34,
      imageFileFormat: '.png',
      name: 'Kraftraum',
      description: ''
    },
*/
</script>
